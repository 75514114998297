import getMetaValue from 'services/tokens';

export default async function post(path: string, payload = {}): Promise<{}> {
  const result = await fetch(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getMetaValue('csrf-token'),
    },
    body: JSON.stringify(payload),
  });

  return result.json();
}
