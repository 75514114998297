export default function setNotice(message: string, type: string): void {
  const notification = document.createElement('div');
  notification.classList.add('notification', type);

  const text = document.createTextNode(message);
  notification.appendChild(text);

  const section = document.getElementsByClassName('section')[0];

  section.insertBefore(notification, section.firstChild);
}
