import post from 'services/fetch';
import setNotice from 'services/setNotice';
import getMetaValue from 'services/tokens';

declare namespace Chargebee {
  function init(any: unknown): any;
  function openCheckout(any: unknown): any;
  function setPortalSession(any: unknown): any;
  function createChargebeePortal(any: unknown): any;
}

function selectedPlan(): string {
  const selector = document.getElementById('plan_id') as HTMLSelectElement;
  let plan;

  if (selector) {
    plan = selector.value;
  }

  return plan || '';
}
export default function inject(): void {
  document.addEventListener('turbolinks:load', () => {
    const chargebeeInstance = Chargebee.init({ site: getMetaValue('chargebee-site') });
    const checkoutButtons = document.querySelectorAll('.chargebee-checkout');
    const portalButtons = document.querySelectorAll('.chargebee-portal');

    if (checkoutButtons.length > 0) {
      checkoutButtons.forEach((button) => {
        button.addEventListener('click', () => {
          chargebeeInstance.openCheckout({
            hostedPage: () => post('/checkouts/new', { plan_id: selectedPlan() }),
            error: () => {
              setNotice('Something went wrong, please try again, or contact us if the problem persists.', 'is-danger');
            },
          });
        });
      });
    }

    if (portalButtons.length > 0) {
      portalButtons.forEach((button) => {
        button.addEventListener('click', () => {
          chargebeeInstance.setPortalSession(() => post('/checkouts/portal').catch(() => {
            setNotice('Something went wrong, please try again, or contact us if the problem persists.', 'is-danger');
          }));

          const portal = chargebeeInstance.createChargebeePortal();

          portal.open();
        });
      });
    }
  });
}
