import {
  PaymentIntent,
  Options,
  PaymentInfo,
  Callbacks,
  AdditionalData,
  Gateway,
} from '@/extensions/three_domain_secure/common/types';
import {PaymentInfo as NetbankingPaymentInfo} from '@/plugins/payments/netbanking_emandates/types';
import {PaymentInfo as OnlineBankingPolandPaymetInfo} from '@/plugins/payments/online_banking_poland/types';
import {PaymentInfo as BancontactPaymentInfo} from '@/plugins/payments/bancontact/types';
import {PaymentInfo as UpiPaymentInfo} from '@/plugins/payments/upi/types';
import {PaymentInfo as DirectDebitPaymentInfo} from '@/plugins/payments/direct_debit/types';
import {PaymentInfo as BoletoPaymentInfo} from '@/plugins/payments/boleto/types';
import {PaymentInfo as KlarnaPayNowPaymentInfo} from '@/plugins/payments/klarna_pay_now/types';

import {ButtonOption} from '@/plugins/payments/google_pay/types';
import {Options as PaypalOptions} from '@/plugins/payments/paypal_express_checkout/types';
import {Options as VenmoOptions} from '@/plugins/payments/venmo/types';
import CbWindowManager from '@/models/cb-window-manager';

export enum ComponentType {
  Card = 'card',
  Bank = 'bank_account',
  IDeal = 'ideal',
  Dotpay = 'dotpay',
  FasterPayments = 'faster_payments',
  PayTo = 'pay_to',
  SepaInstantTransfer = 'sepa_instant_transfer',
  Paypal = 'paypal',
  Netbanking = 'netbanking',
  Applepay = 'applepay',
  Venmo = 'venmo',
  OnlineBankingPoland = 'online_banking_poland',
}

export interface Component {
  name: string;
  type: ComponentType;
  mount(id?): Promise<boolean>;
  framesCreated(): string[];
  delegateEvent(event): void;
}

export interface CardComponentInterface extends Component {
  tokenize(data?);
  authorizeWith3ds(
    paymentIntent: PaymentIntent,
    additionalData: AdditionalData,
    callbacks: Callbacks
  ): Promise<PaymentIntent>;
}

export interface ThreeDSHandler {
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  updatePaymentIntent(paymentIntent);
  handleCardPayment(paymentInfo: PaymentInfo, callbacks?: Callbacks): Promise<PaymentIntent>;
  cancel(reason?: string): Promise<PaymentIntent>;
  getPaymentIntent(): PaymentIntent;
  openNewWindow(): void;
}

export interface IDealPayment extends SelectBank, RedirectPayment {}

export interface SofortPayment extends RedirectPayment {}

export interface GiropayPayment extends RedirectPayment {}

export interface DotpayPayment extends SelectBank, RedirectPayment {}

export interface FasterPymtsPayment extends SelectBank, RedirectPayment {}

export interface PayToPayment extends RedirectPayment {}

export interface SepaInstantTransferPayment extends SelectBank, RedirectPayment {}

interface SelectBank {
  mountBankList(id: string, options?: any): Promise<any>;
  getSelectedBank(): any;
}

export interface RedirectPayment extends PaymentMethod {
  setWindowManager(windowManager: CbWindowManager);
  setRedirectMode(val: boolean);
}

export interface PaymentMethod {
  handlePayment(options: PaymentOptions): Promise<any>;
}

export interface GooglePayment {
  mountPaymentButton(id: string, style?: ButtonOption): Promise<any>;
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  updatePaymentIntent(paymentIntent: PaymentIntent);
  getPaymentIntent(): PaymentIntent;
  handlePayment(callbacks?: Callbacks): Promise<any>;
}

export interface PaypalPayment {
  mountPaymentButton(id: string, option: PaypalOptions): Promise<any>;
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  updatePaymentIntent(paymentIntent: PaymentIntent);
  getPaymentIntent(): PaymentIntent;
  handlePayment(callbacks?: Callbacks): Promise<PaymentIntent>;
}

export interface VenmoPayment {
  mountPaymentButton(id: string, option: VenmoOptions): Promise<any>;
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  updatePaymentIntent(paymentIntent: PaymentIntent);
  getPaymentIntent(): PaymentIntent;
  handlePayment(callbacks?: Callbacks): Promise<PaymentIntent>;
}

export interface BancontactPayment {
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  updatePaymentIntent(paymentIntent: PaymentIntent);
  getPaymentIntent(): PaymentIntent;
  handlePayment(paymentInfo: BancontactPaymentInfo, callbacks?: Callbacks): Promise<any>;
}

export interface NetbankingPayment {
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  getPaymentIntent(): PaymentIntent;
  handlePayment(paymentInfo: NetbankingPaymentInfo, callbacks?: Callbacks): Promise<any>;
  fetchBankList(options?: any): Promise<any>;
}

export interface OnlineBankingPolandPayment {
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  getPaymentIntent(): PaymentIntent;
  handlePayment(paymentInfo: OnlineBankingPolandPaymetInfo, callbacks?: Callbacks): Promise<any>;
  fetchBankList(options?: any): Promise<any>;
}

export interface KlarnaPayNowPayment {
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  updatePaymentIntent(paymentIntent: PaymentIntent);
  getPaymentIntent(): PaymentIntent;
  handlePayment(paymentInfo: KlarnaPayNowPaymentInfo, callbacks?: Callbacks): Promise<any>;
}

export type PaymentOptions = {
  paymentIntent: Function;
  paymentInfo?: any;
  callbacks?: Callbacks;
  redirectMode?: boolean;
  iframeMode?: boolean;
};

export enum TabRedirectPayments {
  ideal,
  sofort,
  giropay,
  dotpay,
  bancontact,
  direct_debit,
  faster_payments,
  pay_to,
  sepa_instant_transfer,
  klarna_pay_now,
}

export const PAYMENT_AUTH_REDIRECT_WINDOW_NAME = 'cb_payment_auth_redirect_window';
export interface ApplePayment {
  handlePayment(callbacks?: Callbacks): Promise<any>;
}

export interface UpiPayment {
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  updatePaymentIntent(paymentIntent: PaymentIntent);
  getPaymentIntent(): PaymentIntent;
  handlePayment(paymentInfo: UpiPaymentInfo, callbacks?: Callbacks): Promise<any>;
}

export interface DirectDebitPayment {
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  updatePaymentIntent(paymentIntent: PaymentIntent);
  getPaymentIntent(): PaymentIntent;
  handlePayment(paymentInfo: DirectDebitPaymentInfo, callbacks?: Callbacks): Promise<any>;
}

export interface TestCardsHandlerInterface {
  setTestCards: Function;
  renderTestCards: Function;
  hasTestCards: Function;
  show: Function;
  hide: Function;
}

export interface BoletoPayment {
  setPaymentIntent(paymentIntent: PaymentIntent, options: Options);
  updatePaymentIntent(paymentIntent: PaymentIntent);
  getPaymentIntent(): PaymentIntent;
  handlePayment(paymentInfo: BoletoPaymentInfo, callbacks?: Callbacks): Promise<any>;
}
