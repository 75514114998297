export default function getMetaValue(name: string): string {
  const element = document.querySelector(`meta[name="${name}"]`);
  let token;

  if (element) {
    token = element.getAttribute('content');
  }

  return token || '';
}
